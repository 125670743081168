import React from "react";
import { parseJwt } from "./helper";
import { useHistory } from "react-router-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";
import group from "./group.svg";
import SocketClient from "./util/newsockets";

const audioContext = new (window.AudioContext || window.webkitAudioContext)();
const digitChoices = Array.from({ length: 9 }, (_, i) => i + 1);
const mode = "both";
const doNothing = (e) => e.preventDefault();

const soundFile = `https://assets.joinaq.com/sounds/inquisitiveness.mp3`;

const playNotification = () => {
  webAudioTouchUnlock(audioContext)
    .then(() => {
      try {
        new Audio(soundFile).play();
      } catch (e) {}
    })
    .catch(function (e) {});
};

const webAudioTouchUnlock = (context) => {
  return new Promise(function (resolve, reject) {
    if (context.state === "suspended" && "ontouchstart" in window) {
      const unlock = function () {
        context.resume().then(
          function () {
            document.body.removeEventListener("message", unlock);
            document.body.removeEventListener("touchstart", unlock);
            document.body.removeEventListener("touchend", unlock);

            resolve(true);
          },
          function (reason) {
            reject(reason);
          }
        );
      };

      document.body.addEventListener("message", unlock, false);
      document.body.addEventListener("touchstart", unlock, false);
      document.body.addEventListener("touchend", unlock, false);
    } else {
      resolve(false);
    }
  });
};

function App() {
  const router = useHistory();
  const socketClient = React.useRef(new SocketClient());
  const [entrance, setEntrance] = React.useState(null);
  const [channel, setChannel] = React.useState(null);
  const [photo, setPhoto] = React.useState("");
  const [logo, setLogo] = React.useState("");
  const [branch, setBranch] = React.useState("");
  const [name, setName] = React.useState("");
  const [token, setToken] = React.useState(null);
  const [maxPax, setMaxPax] = React.useState(50);
  const [occupancy, setOccupancy] = React.useState(0);
  const { addToast } = useToasts();

  const logOut = () => {
    localStorage.clear();
    router.replace("/bye");
  };

  const changePax = React.useCallback((delta) => {
    socketClient.current.sendDelta(delta).then();
  }, []);

  React.useEffect(() => {
    if (channel) {
      socketClient.current
        .initialize(
          "wss://traffichost.herokuapp.com",
          channel,
          (data) => {
            setOccupancy(data.value);
            try {
              playNotification();
              navigator.vibrate([100, 100, 200]);
            } catch (e) {
              doNothing();
            }
          },
          () => {
            socketClient.current.join(channel).then();
          }
        )
        .then();
    }
  }, [channel]);

  React.useEffect(() => {
    const _token = localStorage.getItem("hostToken");
    const sessionData = parseJwt(_token);
    setToken(_token);
    setChannel(sessionData.branch_id);
    setPhoto(sessionData.photo);
    setName(sessionData.first_name);
    setBranch(sessionData.branch_name);
    setLogo(sessionData.logo);
    setEntrance(sessionData.branch_id);
    setMaxPax(sessionData.covid_occupancy);

    window.addEventListener("doubleclick", doNothing);
    return () => window.removeEventListener("doubleclick", doNothing);
  }, [addToast]);

  React.useEffect(() => {
    const restore = (event) => {
      if (event.visibilityState === "visible") changePax(0);
    };

    window.addEventListener("visibilitychange", restore);
    return () => window.removeEventListener("visibilitychange", restore);
  }, [changePax]);

  return (
    <div className="main">
      <div className="navbar">
        <div style={{ display: "flex" }}>
          <img alt="# " src="https://assets.joinaq.com/assets/logo.svg" />
        </div>
        <div></div>
        <div></div>
        <a href="# " onClick={logOut}>
          <div className={"personal"}>
            <span>{name}</span>
            <div style={{ display: "flex" }}>
              <img
                alt="# "
                src={photo}
                height={48}
                style={{ borderRadius: 700 }}
              />
            </div>
          </div>
        </a>
      </div>
      {/*<div className="head">
          <div className="centered-block">
            <div>
              <select className="select-css" onChange={changeMode}>
                <option defaultValue={mode} value={"both"}>
                  Entrance & Exit Mode
                </option>
                <option value={"entrance"}>Entrance Mode</option>
                <option value={"exit"}>Exit Mode</option>
              </select>
            </div>
          </div>
        </div>*/}

      <div className={mode === "both" ? "pad" : "single"}>
        {["both", "entrance"].includes(mode) && (
          <div className="centered-block entrance">
            <h1>Walked In</h1>
            <div className={"buttons"}>
              {digitChoices.map((digit) => (
                <button
                  key={digit}
                  disabled={digit > maxPax - occupancy || !channel}
                  onClick={() => changePax(digit)}
                >
                  {digit}
                  <div style={{ display: "block", fontSize: 11 }}>Entered</div>
                </button>
              ))}
            </div>
          </div>
        )}
        {["both", "exit"].includes(mode) && (
          <div className="centered-block exit">
            <h1>Walked Out</h1>
            <div className={"buttons"}>
              {digitChoices.map((digit) => (
                <button
                  key={digit}
                  disabled={digit > occupancy}
                  onClick={() => changePax(-digit)}
                >
                  {digit}
                  <div style={{ display: "block", fontSize: 11 }}>Exited</div>
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="footer">
        <img alt={branch} src={logo} /> <span>{branch}</span>
        <span
          style={{
            fontWeight: "bold",
            display: "grid",
            gridTemplateColumns: "24px 1fr",
            gridColumnGap: 8,
            alignItems: "center",
            color:
              occupancy < maxPax * 0.5
                ? "green"
                : occupancy > (maxPax * 3) / 4
                ? "red"
                : "orange",
          }}
        >
          <img alt="# " src={group} /> {occupancy} of {maxPax}
        </span>
      </div>
    </div>
  );
}

const AppWith = () => (
  <ToastProvider>
    <App />
  </ToastProvider>
);

export default AppWith;
