import React from "react";
import "./style.css";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import Login from "./Login";
import KeyPad from "./NewKeyPad";
import Bye from "./Bye";

const Protected = props => {
  const router = useHistory();
  if (localStorage.getItem("hostToken")) return <Route {...props}></Route>;

  router.replace("/");
  return null;
};

function App() {
  return (
    <Router>
      <Route path="/" exact component={Login}></Route>
      <Protected path="/bye" exact component={Bye}></Protected>
      <Protected path="/pad" exact component={KeyPad}></Protected>
    </Router>
  );
}

export default App;
