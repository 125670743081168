import React from "react";
import { useHistory } from "react-router-dom";

const Styles = {
  container: {
    width: "100%",
    padding: 24,
    display: "grid",
    alignItems: "center",
    justifyItems: "center",
    gridTemplateRows: "1fr",
    height: "100vh",
    textAlign: "center"
  },
  logoWrapper: {
    display: "grid",
    justifyContent: "center",
    gridRowGap: 12,
    alignItems: "center"
  },
  logo: {
    width: "200px",
    maxWidth: "100%"
  }
};

function Bye() {
  const router = useHistory();
  React.useEffect(() => {
    const t = setTimeout(() => router.replace("/"), 5000);
    return () => clearTimeout(t);
  });
  return (
    <div style={Styles.container}>
      <div style={Styles.logoWrapper}>
        <div>Thank you for using</div>
        <a href={"https://joinaq.com"}>
          <img
            alt=" "
            style={Styles.logo}
            src={"https://assets.joinaq.com/assets/logo.svg"}
          />
        </a>
        <div>... goodbye...</div>
      </div>
    </div>
  );
}

export default Bye;
