class SocketClient {
  room;
  socketServer;
  socket;

  initialize = async (socketServerUrl, room, onMessage, onOpen) => {
    this.room = room;
    this.socketServerUrl = socketServerUrl;
    this.onMessage = onMessage;

    this.socket = new WebSocket(this.socketServerUrl);
    this.socket.onmessage = (event) => {
      const update = JSON.parse(event.data);
      onMessage(update)
    }
    this.socket.onopen = onOpen;
    this.socket.onclose = (message) => {
      console.log('Closing: ', message)
    }
    this.socket.onerror = (message) => {
      this.initialize(this.socketServerUrl, this.room, this.onMessage);
      console.log('Errored:', message)
    }
    return this;
  }

  sendDelta = async (delta) => {
    this.socket.send(JSON.stringify({action: 'traffic', delta, room: this.room}));
    return this;
  }

  join = async () => {
    try{
        this.socket.send(JSON.stringify({action: 'join', delta:0, room: this.room}));
    } catch(e) {
      console.log(e)
    }
  }
}

export default SocketClient;
