import React from "react";
import { useHistory } from "react-router-dom";
import icon from "./icon.svg";

const Styles = {
  container: {
    width: "60%",
    maxWidth: 350,
    minWidth: 320,
    margin: "auto",
    padding: 24,
    display: "grid",
    gridRowGap: 8
  },
  buttonNormal: {
    padding: "8px 16px",
    fontSize: "1em",
    background: "#cc0000",
    color: "white"
  },
  input: {
    width: "100%",
    padding: "8px 16px",
    fontSize: "1em"
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  logo: {
    width: "200px",
    maxWidth: "100%"
  },
  centered: {
    textAlign: "center"
  }
};

function App() {
  const [signingIn, setSigningIn] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const emailRef = React.useRef("");
  const passwordRef = React.useRef("");
  const router = useHistory();

  const onLoginSuccess = token => {
    setSigningIn(false);
    if (token) {
      localStorage.setItem("hostToken", token);
      router.replace("/pad");
    }
  };

  const onSubmit = e => {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    setMessage("");
    if (email && password) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("email", email);
      urlencoded.append("password", password);

      setSigningIn(true);
      fetch("https://api.qmngr.com/auth/host/signin", {
        method: "post",
        body: urlencoded
      })
        .then(response => response.json())
        .then(json => {
          if (json && "token" in json) {
            onLoginSuccess(json.token);
          } else {
            setMessage("Incorrect details supplied");
          }
        })
        .catch();
    }
    e.preventDefault();
  };

  const clearMessage = React.useCallback(() => void setMessage(""), []);

  return (
    <div>
      <form style={Styles.container} onSubmit={onSubmit}>
        <div style={{ ...Styles.row, ...Styles.logoWrapper }}>
          <img alt=" " style={Styles.logo} src={icon} />
        </div>
        <div style={Styles.row}>
          <input
            style={Styles.input}
            ref={emailRef}
            autoComplete="current-password"
            placeholder="Email or Mobile Number"
            onChange={clearMessage}
          />
        </div>
        <div style={Styles.row}>
          <input
            style={Styles.input}
            ref={passwordRef}
            type="password"
            autoComplete="current-password"
            placeholder="Concierge Password"
            onChange={clearMessage}
          />
        </div>
        <div style={Styles.row}>
          <button style={Styles.buttonNormal} disabled={signingIn}>
            {signingIn ? "Signing In..." : "Sign In"}
          </button>
          <div style={Styles.centered}>{message}</div>
        </div>
      </form>
    </div>
  );
}

export default App;
